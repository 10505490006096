<template>
  <div
    class="shortcut"
    :class="[size]"
  >
    <div class="key">
      {{ keyLabel }}
    </div>
    <div class="label">
      {{ t(`shortcutActions.${action}`) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { ShortcutAction } from './shortcuts';

  export default defineComponent({
    name: 'Shortcut',

    props: {
      size: {
        type: String as PropType<'md' | 'lg'>,
        default: 'md',
      },
      keyLabel: {
        type: String,
        required: true,
      },
      action: {
        type: String as PropType<ShortcutAction>,
        required: true,
      },
    },

    setup() {
      const { t } = useI18n();
      return { t };
    },
  });
</script>

<style lang="scss" scoped>
.shortcut {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  padding: 0 .625em;
  border: 1px solid rgb(var(--color-secondary-border));
  border-radius: 0.375rem;
  height: 2.5em;

  .key {
    line-height: 1;
    font-size: .75em;
    font-weight: 500;
    border-radius: .25rem;
    border: 1px solid rgb(var(--color-secondary-border));
    box-shadow: inset 0px -1px rgb(var(--color-secondary-border));
    padding: .3em .375em .4em;
    color: rgb(var(--color-primary-text));

    margin-right: .5rem;
  }

  .label {
    display: inline-block;
    font-weight: normal;
    color: rgb(var(--color-text-primary));
    font-size: .875em;
  }

  &.lg {
    font-size: 1.375rem;
  }
}
</style>
