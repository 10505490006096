import { PressObject } from '@/config/keyboards/dataTypes';
import { LessonData } from '@/config/dataTypes';
import { Speedometer } from './speedometer';

enum TrainerState {
  Created,
  Initialized,
  Running,
  Paused,
}

type CharState = {
  index: number,
  requiredChar: string,
  typedChar: string,
}

class Trainer {
  private _state: TrainerState;

  private _textFromGenerator: string[];

  private _textState: CharState[][];

  private _typosCount: number;

  private _cursorIndex: number;

  private _speedometer: Speedometer;

  private _speedUnits: 'wpm' | 'cpm';

  constructor(speedUnits: 'wpm' | 'cpm') {
    this._state = TrainerState.Created;
    this._textFromGenerator = [];
    this._textState = [];
    this._typosCount = 0;
    this._cursorIndex = 0;
    this._speedometer = new Speedometer();
    this._speedUnits = speedUnits;
  }

  public init(textFromGenerator: string[]): void {
    this._state = TrainerState.Initialized;
    this._textFromGenerator = textFromGenerator;

    let charIndex = 0;
    textFromGenerator.forEach((row, i) => {
      const rowState: CharState[] = [];

      for (let j = 0; j < row.length; j++) {
        rowState.push({
          index: charIndex,
          requiredChar: row[j],
          typedChar: '',
        });
        charIndex++;
      }

      // Add space to the end of each line except last one
      if (i !== textFromGenerator.length - 1) {
        rowState.push({
          index: charIndex,
          requiredChar: ' ',
          typedChar: '',
        });

        charIndex++;
      }

      this._textState.push(rowState);
    });
  }

  public start(): void {
    this._state = TrainerState.Running;
    this._speedometer.start();
  }

  public pause(): void {
    this._state = TrainerState.Paused;
    this._speedometer.pause();
  }

  public resume(): void {
    this._state = TrainerState.Running;
    this._speedometer.resume();
  }

  public restart(): void {
    this._textState = [];
    this._typosCount = 0;
    this._cursorIndex = 0;
    this._speedometer = new Speedometer();
    this.init(this._textFromGenerator);
  }

  public speed(): number {
    const speedCpm = this._speedometer.getCurrentSpeed();

    if (this._speedUnits === 'wpm') {
      return Math.round(speedCpm / 5);
    }

    return Math.round(speedCpm);
  }

  public accuracy(): number {
    return this._typosCount ? Math.round((1 - this._typosCount / this.textLength()) * 100) : 100;
  }

  public wrongSequence(): boolean {
    return this.firstWrongIndex() !== -1;
  }

  public firstWrongIndex(): number {
    for (let i = 0; i < this._textState.length; i++) {
      const row = this._textState[i];
      const wrongChars = row.filter((char) => char.typedChar && char.typedChar !== char.requiredChar);

      if (wrongChars.length) {
        return wrongChars[0].index;
      }
    }

    return -1;
  }

  public cursorIndex(): number {
    return this._cursorIndex;
  }

  public textState(): CharState[][] {
    return this._textState;
  }

  public charToType(): string | null {
    if (this.wrongSequence()) {
      return 'Backspace';
    }

    if (this.isPaused()) {
      return null;
    }

    const charState = this._getCharState(this._cursorIndex);
    return charState ? charState.char.requiredChar : null;
  }

  public addChar(pressObj: PressObject): void {
    const charState = this._getCharState(this._cursorIndex);

    if (!charState) {
      return;
    }

    if (charState.char.requiredChar === pressObj.key) {
      this._speedometer.addCorrectPress();
    } else if (!this.wrongSequence()) {
      this._typosCount++;
    }

    charState.char.typedChar = pressObj.key;
    this._cursorIndex++;
  }

  public removeChar(): boolean {
    const prevChar = this._getCharState(this._cursorIndex - 1);

    if (prevChar) {
      prevChar.char.typedChar = '';
      this._cursorIndex--;
      return true;
    }

    return false;
  }

  public isPaused(): boolean {
    return this._state === TrainerState.Paused;
  }

  public isFinished(): boolean {
    return !this.wrongSequence() && this._cursorIndex >= this.textLength();
  }

  public textLength(): number {
    let len = 0;
    this._textFromGenerator.forEach((row) => {
      len += row.length + 1;
    });
    return Math.max(len - 1, 0);
  }

  public getResults(): LessonData {
    const lessonDuration = this._speedometer.timeFromStart();

    return {
      speed: this.textLength() * (60000 / lessonDuration.asMilliseconds()),
      accuracy: this.accuracy(),
      duration: lessonDuration,
      text: this._textFromGenerator,
    };
  }

  private _getCharState(index: number): {char: CharState, row: number} | null {
    for (let i = 0; i < this._textState.length; i++) {
      const row = this._textState[i];
      for (let j = 0; j < row.length; j++) {
        if (row[j].index === index) {
          return {
            char: row[j],
            row: i + 1,
          };
        }
      }
    }

    return null;
  }
}

export { Trainer };
