
  import {
    computed, ref, defineComponent, onUnmounted, onMounted,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter, useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import axios from 'axios';

  import DarkLightImage from '@/components/DarkLightImage.vue';
  import Header from '@/components/Header.vue';
  import Keyboard from '@/components/keyboard/Keyboard.vue';
  import Shortcut from '@/components/key-shortcut/Shortcut.vue';

  import { callCloudFunction, codeProxy } from '@/utils';
  import { PressObject, LayoutKeyCodes, KeyCode } from '@/config/keyboards/dataTypes';
  import { shortcutKeys, shortcutActions } from '@/components/key-shortcut/shortcuts';
  import { Trainer } from './trainer';

  export default defineComponent({
    name: 'Trainer',

    components: {
      Header,
      DarkLightImage,
      Keyboard,
      Shortcut,
    },

    setup() {
      const { t, locale } = useI18n({ useScope: 'global' });
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      const ROWS_COUNT = 3;
      const ROW_LENGTH = 56;

      const speedUnits = locale.value === 'en-US' ? 'wpm' : 'cpm';
      const lesson = ref<Trainer>(new Trainer(speedUnits));
      const firstWrongIndex = ref(-1);

      // Speed metric

      const currentSpeed = ref(0);
      const speedIntervalId = ref(0);

      const deactivateSpeedometer = () => {
        if (speedIntervalId.value) {
          clearInterval(speedIntervalId.value);
          speedIntervalId.value = 0;
        }
      };

      const activateSpeedometer = () => {
        deactivateSpeedometer();

        // Here added invoking as a method of window for support TypeScript to infer type
        speedIntervalId.value = window.setInterval(() => {
          currentSpeed.value = lesson.value.speed();
        }, 300);
      };

      // Get keyboard layout and lesson text

      const layoutProps = store.getters['layout/props'];

      if (!layoutProps) {
        window.location.replace('/');
      }

      if (route.query.repeat && store.getters['lesson/last']) {
        lesson.value.init(store.getters['lesson/last'].text);
      } else {
        axios
          .post(callCloudFunction('generator'), {
            letters: localStorage.getItem('generatorOptionsLetters') !== 'false',
            numbers: localStorage.getItem('generatorOptionsNumbers') !== 'false',
            symbols: localStorage.getItem('generatorOptionsSymbols') !== 'false',
            layout: layoutProps,
            rowsCount: ROWS_COUNT,
            rowLength: ROW_LENGTH,
          })
          .then((result) => {
            lesson.value.init(result.data);
          });
      }

      // Keybinds

      const keybinds = {
        pause: {
          key: shortcutKeys.esc,
          action: shortcutActions.pause,
        },
        resume: {
          key: shortcutKeys.space,
          action: shortcutActions.continue,
        },
        restart: {
          key: shortcutKeys.esc,
          action: shortcutActions.restart,
        },
      };

      const navbarKeybind = computed(() => (lesson.value?.isPaused() ? keybinds.restart : keybinds.pause));

      const handleKeybind = (e: KeyboardEvent) => {
        const code = codeProxy(e.code as KeyCode<null>, window.navigator, layoutProps.format);

        if (lesson.value && code === navbarKeybind.value.key.code) {
          e.preventDefault();

          if (navbarKeybind.value.action === shortcutActions.pause) {
            lesson.value.pause();
            deactivateSpeedometer();
          } else {
            lesson.value.restart();
            activateSpeedometer();
            currentSpeed.value = 0;
          }
        } else if (lesson.value?.isPaused() && code === keybinds.resume.key.code) {
          e.preventDefault();
          lesson.value.resume();
          activateSpeedometer();
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', handleKeybind);
      });

      onUnmounted(() => {
        document.removeEventListener('keydown', handleKeybind);
        deactivateSpeedometer();
      });

      // Handle press events from Keyboard

      const press = (pressObj: PressObject) => {
        if (LayoutKeyCodes.includes(pressObj.code as KeyCode<typeof LayoutKeyCodes[number]>)) {
          if (lesson.value.cursorIndex() === 0) {
            lesson.value.start();
            activateSpeedometer();
          }

          lesson.value.addChar(pressObj);

          if (firstWrongIndex.value === -1) {
            firstWrongIndex.value = lesson.value.firstWrongIndex();
          }

          if (lesson.value.isFinished()) {
            deactivateSpeedometer();
            store.dispatch('lesson/save', lesson.value.getResults());
            router.push({ name: 'result' });
          }

          return;
        }

        if (pressObj.code === 'Backspace') {
          lesson.value.removeChar();
          firstWrongIndex.value = lesson.value.firstWrongIndex();
        }
      };

      return {
        ROW_LENGTH, firstWrongIndex, navbarKeybind, keybinds, lesson, press, t, layoutProps, currentSpeed, speedUnits,
      };
    },
  });
