import moment, { Duration, Moment } from 'moment';

class Speedometer {
  private _periodLimit: number; // ms

  private _startMoment: Moment | null;

  private _pauseMoment: Moment | null;

  private _pressMoments: Moment[];

  constructor(periodLimit = 10000) {
    this._periodLimit = periodLimit;
    this._startMoment = null;
    this._pauseMoment = null;
    this._pressMoments = [];
  }

  public start(): void {
    this._startMoment = moment();
  }

  public pause(): void {
    if (this._startMoment && !this._pauseMoment) {
      this._pauseMoment = moment();
    }
  }

  public resume(): void {
    if (this._startMoment && this._pauseMoment) {
      const ms = moment().diff(this._pauseMoment);

      this._startMoment.add(ms, 'ms');
      for (let i = 0; i < this._pressMoments.length; i++) {
        this._pressMoments[i].add(ms, 'ms');
      }

      this._pauseMoment = null;
    }
  }

  public getCurrentSpeed(): number {
    let speed = 0;

    if (this._pressMoments.length) {
      const timeTo = moment();
      const timeFrom = timeTo.subtract(this._periodLimit, 'ms');
      const validPressMoments = this._pressMoments.filter((m) => m.isAfter(timeFrom));

      if (validPressMoments.length) {
        const count = validPressMoments.length;
        const first = validPressMoments[0];
        const diff = moment().diff(first);
        speed = count * (60000 / diff);
      }
    }

    return speed;
  }

  public timeFromStart(): Duration {
    return moment.duration(moment().diff(this._startMoment));
  }

  public addCorrectPress(): void {
    this._pressMoments.push(moment());
  }
}

export { Speedometer };
