<template>
  <section
    class="trainer"
    :class="{'paused': lesson.isPaused()}"
  >
    <teleport to="#app-header">
      <Header
        :metrics="{
          speed: {value: currentSpeed, units: t(`speedUnits.${speedUnits}`)},
          accuracy: {value: lesson.accuracy(), units: '%'}}"
      >
        <template #trainer-control>
          <Shortcut
            size="md"
            :key-label="navbarKeybind.key.label.trim()"
            :action="navbarKeybind.action.trim()"
          />
        </template>
      </Header>
    </teleport>

    <div
      v-if="lesson.textLength()"
      class="text-wrapper"
    >
      <div
        v-for="(row, i) in lesson.textState()"
        :key="i"
        class="row"
      >
        <div
          v-for="(charState, j) in row"
          :key="charState.index"
          class="cell"
          :class="{
            'correct': charState.typedChar && charState.typedChar === charState.requiredChar,
            'wrong': charState.typedChar && firstWrongIndex !== -1 && firstWrongIndex <= charState.index,
            'cursor': lesson.cursorIndex() === charState.index,
            'last-typed': lesson.cursorIndex() - 1 === charState.index,
            'last-in-row': row.length - 1 === j,
          }"
          :style="{'flex': `0 0 ${100 / ROW_LENGTH}%`}"
        >
          <div class="inner">
            {{ charState.typedChar || charState.requiredChar }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="lesson.textLength()"
      class="keyboard-wrapper"
    >
      <!-- <div v-show="interactiveMessage" class="interactive-message">{{ interactiveMessage }}</div> -->

      <Keyboard
        :char-to-type="lesson.charToType()"
        :handle-typing="!lesson.isPaused()"
        :layout-props="layoutProps"
        :simulate-layout="true"
        :show-hands="true"
        @press="press"
      />
    </div>

    <div
      v-show="lesson.isPaused()"
      class="pause-view trainer-size-view"
    >
      <div class="wrapper">
        <Shortcut
          type="shortcut"
          size="lg"
          :key-label="keybinds.resume.key.label"
          :action="keybinds.resume.action"
        />
      </div>
    </div>

    <div
      v-if="!lesson.textLength()"
      class="loading-placeholder trainer-size-view"
    >
      <DarkLightImage
        path="loader"
        ext="svg"
        alt="Loading..."
      />
    </div>
  </section>
</template>

<script lang="ts">
  import {
    computed, ref, defineComponent, onUnmounted, onMounted,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter, useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import axios from 'axios';

  import DarkLightImage from '@/components/DarkLightImage.vue';
  import Header from '@/components/Header.vue';
  import Keyboard from '@/components/keyboard/Keyboard.vue';
  import Shortcut from '@/components/key-shortcut/Shortcut.vue';

  import { callCloudFunction, codeProxy } from '@/utils';
  import { PressObject, LayoutKeyCodes, KeyCode } from '@/config/keyboards/dataTypes';
  import { shortcutKeys, shortcutActions } from '@/components/key-shortcut/shortcuts';
  import { Trainer } from './trainer';

  export default defineComponent({
    name: 'Trainer',

    components: {
      Header,
      DarkLightImage,
      Keyboard,
      Shortcut,
    },

    setup() {
      const { t, locale } = useI18n({ useScope: 'global' });
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      const ROWS_COUNT = 3;
      const ROW_LENGTH = 56;

      const speedUnits = locale.value === 'en-US' ? 'wpm' : 'cpm';
      const lesson = ref<Trainer>(new Trainer(speedUnits));
      const firstWrongIndex = ref(-1);

      // Speed metric

      const currentSpeed = ref(0);
      const speedIntervalId = ref(0);

      const deactivateSpeedometer = () => {
        if (speedIntervalId.value) {
          clearInterval(speedIntervalId.value);
          speedIntervalId.value = 0;
        }
      };

      const activateSpeedometer = () => {
        deactivateSpeedometer();

        // Here added invoking as a method of window for support TypeScript to infer type
        speedIntervalId.value = window.setInterval(() => {
          currentSpeed.value = lesson.value.speed();
        }, 300);
      };

      // Get keyboard layout and lesson text

      const layoutProps = store.getters['layout/props'];

      if (!layoutProps) {
        window.location.replace('/');
      }

      if (route.query.repeat && store.getters['lesson/last']) {
        lesson.value.init(store.getters['lesson/last'].text);
      } else {
        axios
          .post(callCloudFunction('generator'), {
            letters: localStorage.getItem('generatorOptionsLetters') !== 'false',
            numbers: localStorage.getItem('generatorOptionsNumbers') !== 'false',
            symbols: localStorage.getItem('generatorOptionsSymbols') !== 'false',
            layout: layoutProps,
            rowsCount: ROWS_COUNT,
            rowLength: ROW_LENGTH,
          })
          .then((result) => {
            lesson.value.init(result.data);
          });
      }

      // Keybinds

      const keybinds = {
        pause: {
          key: shortcutKeys.esc,
          action: shortcutActions.pause,
        },
        resume: {
          key: shortcutKeys.space,
          action: shortcutActions.continue,
        },
        restart: {
          key: shortcutKeys.esc,
          action: shortcutActions.restart,
        },
      };

      const navbarKeybind = computed(() => (lesson.value?.isPaused() ? keybinds.restart : keybinds.pause));

      const handleKeybind = (e: KeyboardEvent) => {
        const code = codeProxy(e.code as KeyCode<null>, window.navigator, layoutProps.format);

        if (lesson.value && code === navbarKeybind.value.key.code) {
          e.preventDefault();

          if (navbarKeybind.value.action === shortcutActions.pause) {
            lesson.value.pause();
            deactivateSpeedometer();
          } else {
            lesson.value.restart();
            activateSpeedometer();
            currentSpeed.value = 0;
          }
        } else if (lesson.value?.isPaused() && code === keybinds.resume.key.code) {
          e.preventDefault();
          lesson.value.resume();
          activateSpeedometer();
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', handleKeybind);
      });

      onUnmounted(() => {
        document.removeEventListener('keydown', handleKeybind);
        deactivateSpeedometer();
      });

      // Handle press events from Keyboard

      const press = (pressObj: PressObject) => {
        if (LayoutKeyCodes.includes(pressObj.code as KeyCode<typeof LayoutKeyCodes[number]>)) {
          if (lesson.value.cursorIndex() === 0) {
            lesson.value.start();
            activateSpeedometer();
          }

          lesson.value.addChar(pressObj);

          if (firstWrongIndex.value === -1) {
            firstWrongIndex.value = lesson.value.firstWrongIndex();
          }

          if (lesson.value.isFinished()) {
            deactivateSpeedometer();
            store.dispatch('lesson/save', lesson.value.getResults());
            router.push({ name: 'result' });
          }

          return;
        }

        if (pressObj.code === 'Backspace') {
          lesson.value.removeChar();
          firstWrongIndex.value = lesson.value.firstWrongIndex();
        }
      };

      return {
        ROW_LENGTH, firstWrongIndex, navbarKeybind, keybinds, lesson, press, t, layoutProps, currentSpeed, speedUnits,
      };
    },
  });
</script>

<style lang="scss" scoped>
.trainer {
  display: grid;
  grid: "." minmax(3rem, 1fr)
        "text" min-content
        "." minmax(3rem, 1fr)
        "keyboard" min-content
        / 45rem;
  justify-content: center;
  position: relative;

  .trainer-size-view {
    display: grid;
    place-content: center center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  .loading-placeholder {
    :deep(img) {
      width: 80px;
      height: 80px;
    }
  }

  &.paused {
    .pause-view {
      background: rgb(var(--color-background) / .8);

      .wrapper {
        position: relative;
        bottom: calc(var(--header-height) / 2);
      }
    }

    & > *:not(.pause-view) {
      filter: blur(10px);
    }
  }

  .text-wrapper {
    grid-area: text;
    display: flex;
    flex-direction: column;
    background-color: rgb(var(--color-background));
    font-size: 1.125rem;

    .row {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid rgb(var(--color-secondary-border));

      &:first-child {
        border-top: 1px solid rgb(var(--color-secondary-border));
      }

      .cell {
        $border-radius: .25rem;

        display: flex;
        flex-direction: column;
        padding: .6em 0;

        .inner {
          height: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 1em;
          font-family: 'Roboto Mono', monospace;
        }

        &.correct {
          .inner {
            background-color: rgb(var(--color-success-bg));
            color: rgb(var(--color-success-text));
          }
        }

        &.wrong {
          .inner {
            background-color: rgb(var(--color-danger-bg));
            color: rgb(var(--color-danger-text));
          }
        }

        &:first-child {
          .inner {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
          }
        }

        &.last-typed, &.last-in-row {
          .inner {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
        }

        &.cursor {
          position: relative;

          &:after {
            content: " ";
            display: block;
            position: absolute;
            top: calc(100% - 1px);
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background: rgb(var(--color-primary));
          }
        }
      }
    }
  }
  .keyboard-wrapper {
    grid-area: keyboard;
    position: relative;
  }
}
</style>
