
  import { defineComponent, PropType } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { ShortcutAction } from './shortcuts';

  export default defineComponent({
    name: 'Shortcut',

    props: {
      size: {
        type: String as PropType<'md' | 'lg'>,
        default: 'md',
      },
      keyLabel: {
        type: String,
        required: true,
      },
      action: {
        type: String as PropType<ShortcutAction>,
        required: true,
      },
    },

    setup() {
      const { t } = useI18n();
      return { t };
    },
  });
